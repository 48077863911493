import React from "react";
import { Field, ErrorMessage } from "formik";

function CheckboxGroup(props) {
  const { label, name, options, fullLine, listed, submitForm, ...rest } = props;
  return (
    <fieldset className={"space-y-5 " + fullLine && "flex items-center py-2"}>
      <legend className="font-medium text-sm text-gray-700">{label}</legend>
      <Field name={name}>
        {({ field }) => {
          function handleChange(e) {
            field.onChange(e);
            props.submitForm();
          }
          const checkboxOptions = options.map(option => {
            return (
              <React.Fragment key={option._id}>
                <div className={`relative flex items-start ${listed ? "mt-2" : ""}`}>
                  <div className="flex items-center h-5">
                    <input type="checkbox" id={option._id} name={field.name} onBlur={field.onBlur} onChange={handleChange} {...rest} value={option._id} checked={field.value.includes(option._id)} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer" />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor={option._id} className="font-medium text-gray-700">
                      {option.title}
                    </label>
                  </div>
                </div>
              </React.Fragment>
            );
          });

          return <>{!fullLine ? <div className={"grid gap-2 " + listed ? "grid-cols-1 " : "grid-cols-3"}>{checkboxOptions}</div> : <>{checkboxOptions}</>}</>;
        }}
      </Field>
    </fieldset>
  );
}

export default CheckboxGroup;
