import React from "react";
import { InformationCircleIcon } from "@heroicons/react/outline";

function LightInfoBox({ info }) {
  return (
    <div className="w-full bg-gray-100 rounded-md p-4 flex space-x-2 text-gray-500 text-xs">
      <div>
        <InformationCircleIcon className="h-4 w-4 text-gray-400" />
      </div>
      <div dangerouslySetInnerHTML={{ __html: info }}></div>
    </div>
  );
}

export default LightInfoBox;
