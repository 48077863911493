export const FLASHMESSAGE__SHOP = "FLASHMESSAGE__SHOP";
export const FLASHMESSAGE__DASHBOARD = "FLASHMESSAGE__DASHBOARD";
export const RESET__FLASHMESSAGE = "RESET__FLASHMESSAGE";
export const SET_CART_ITEMS = "SET_CART_ITEMS";

export function flashMessageShop(type, title, subline = "") {
  return {
    type: FLASHMESSAGE__SHOP,
    msg: {
      type,
      title,
      subline
    }
  };
}

export function flashMessageDashboard(type, title, subline = "") {
  return {
    type: FLASHMESSAGE__DASHBOARD,
    msg: {
      type,
      title,
      subline
    }
  };
}

export function resetFlashmessage() {
  return {
    type: RESET__FLASHMESSAGE
  };
}

export function setCartItems(count) {
  return {
    type: SET_CART_ITEMS,
    count: count
  };
}
