import React, { useRef, useState, useEffect } from "react";
import { Field, ErrorMessage } from "formik";

function CoverImgPicker(props) {
  const { name, formik } = props;

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef();

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(image);
      // const formData = new FormData();
      // formData.append(name, image, image.name);
      formik.setFieldValue(name, image);
    } else {
    }
  }, [image]);

  function handleClick(e) {
    e.preventDefault();

    fileInputRef.current.click();
  }

  function handleFileChange(e) {
    const file = e.target.files[0];

    if (file) {
      setImage(file);
    } else {
      setImage(null);
    }
  }

  return (
    <div>
      <label htmlFor="coverImg" className="block text-sm font-medium text-gray-700 mb-1">
        Titelbild
      </label>
      <div className="grid grid-cols-4 gap-6">
        <div>
          {imagePreview ? (
            <div className="aspect-w-4 aspect-h-3 rounded-lg bg-gray-100 overflow-hidden">
              <img src={imagePreview} alt={image.name} className="object-center object-cover" />
            </div>
          ) : (
            <div className="mt-1 flex justify-center aspect-w-4 aspect-h-3 border-2 border-gray-300 border-dashed rounded-md box-border">
              <div className="flex space-y-1 text-center items-center">
                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                  <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          )}
        </div>
        <input type="file" id="coverImg" name="coverImg" className="sr-only" ref={fileInputRef} onChange={handleFileChange} accept="image/jpeg, image/jpg, image/png" />
        <div className="flex items-center">
          <button onClick={handleClick} type="button" className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Bild auswählen
          </button>
        </div>
      </div>
    </div>
  );
}

export default CoverImgPicker;
