import React, { useEffect } from "react";

// Components
import Container from "./Container";

function Page(props) {
  const { title, dashboard } = props;

  let container;

  if (dashboard) {
    container = (
      <>
        {/* <div className="bg-blue-500 w-full h-5" /> Loading... */}
        <div className="py-6 md:mt-6">
          {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
          </div> */}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">{props.children}</div>
        </div>
      </>
    );
  } else {
    container = <Container>{props.children}</Container>;
  }

  useEffect(() => {
    document.title = `ZENAPA - Tourismusrechner`;
    window.scrollTo(0, 0);
  }, [title]);

  return container;
}

export default Page;
