import React from "react";
import Input from "./Input";
import InputWithTag from "./InputWithTag";
import InputWithUnit from "./InputWithUnit";
import MonthWithTag from "./MonthWithTag";
import Textarea from "./Textarea";
import CheckboxGroup from "./CheckboxGroup";
import SimpleCheckbox from "./SimpleCheckbox";
import Select from "./Select";
import SelectWithTag from "./SelectWithTag";
import Range from "./Range";
import CoverImgPicker from "./CoverImgPicker";
import SingleFilePicker from "./SingleFilePicker";
import TravelTimeInput from "./TravelTimeInput";
import Number from "./Number";
import NumberWithUnit from "./NumberWithUnit";
import Password from "./Password";
import Email from "./Email";
// import RadioButtons from './RadioButtons'
// import DatePicker from './DatePicker'
// import ChakraInput from './ChakraInput'

function FormControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return props.before ? <InputWithTag {...rest} /> : props.after ? <InputWithUnit {...rest} /> : <Input {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "checkbox":
      return props.simple ? <SimpleCheckbox {...rest} /> : <CheckboxGroup {...rest} />;
    case "select":
      return props.before ? <SelectWithTag {...rest} /> : <Select {...rest} />;
    case "month":
      return <MonthWithTag {...rest} />;
    case "range":
      return <Range {...rest} />;
    case "coverimg":
      return <CoverImgPicker {...rest} />;
    case "file":
      return props.single ? <SingleFilePicker {...rest} /> : <h1>Nicht implementiert</h1>;
    case "traveltime":
      return <TravelTimeInput {...rest} />;
    case "number":
      return props.after ? <NumberWithUnit {...rest} /> : <Number {...rest} />;
    case "email":
      return <Email {...rest} />;
    case "password":
      return <Password {...rest} />;

    default:
      return <p>Form Type not found</p>;
  }
}

export default FormControl;
