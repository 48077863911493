import { LOGIN, LOGOUT } from "../actions/auth";

const initialState = {
  loggedIn: Boolean(localStorage.getItem("ZenapaToken")),
  user: {
    token: localStorage.getItem("ZenapaToken"),
    firstname: localStorage.getItem("ZenapaFirstName"),
    lastname: localStorage.getItem("ZenapaLastName"),
    email: localStorage.getItem("ZenapaEmail"),
    role: localStorage.getItem("ZenapaUserRole"),
    companyName: localStorage.getItem("ZenapaCompanyName")
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loggedIn: true,
        user: action.user
      };
    case LOGOUT:
      return {
        ...state,
        loggedIn: false
      };
  }

  return state;
};
