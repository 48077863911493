import React, { useEffect } from "react";
import { useSelector } from "react-redux";

function Header(props) {
  const partnerLogoExists = useSelector(state => state.config.partnerlogo);
  const partnerURL = useSelector(state => state.config.partnerlink);

  return (
    <div className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className={"flex flex-col sm:flex-row justify-center gap-3 sm:gap-0 sm:justify-between " + (partnerLogoExists ? "h-24 sm:h-16" : "h-16")}>
          <div className="flex-shrink-0 flex items-center justify-center sm:justify-start">
            <a className="" href="https://zenapa.de">
              <img className="block h-8 w-auto" src={process.env.PUBLIC_URL + "/img/zenapa_logo.png"} alt="ZENAPA Logo" />
            </a>
          </div>
          {partnerLogoExists && (
            <div className="flex-shrink-0 flex items-center justify-center sm:justify-start">
              <a className="" href={partnerURL}>
                <img className="block h-8 w-auto" src={process.env.PUBLIC_URL + "/logo.png"} alt="Partner Logo" />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
