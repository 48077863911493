import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Components
import FormControl from "../../../components/FormUI/FormControl";
import LightInfoBox from "../../../components/LightInfoBox";

function Step_1(props) {
  const [gastro, setGastro] = useState([]);
  const gastroDropdown = props.dropdownData.gastro_dropdown;

  useEffect(() => {
    const temp = [];
    temp.push({ id: "", title: "Bitte auswählen" });

    for (let i = gastroDropdown.length - 1; i >= 0; i--) {
      temp.push({ id: gastroDropdown[i].id, title: gastroDropdown[i].title });
    }

    setGastro(temp);
  }, []);

  const initialValues = {
    stars: "",
    days: "",
    visits: ""
  };

  const validationSchema = Yup.object({
    stars: Yup.number().typeError("Keine gültige Auswahl").required("Bitte wählen Sie die zutreffende Option aus"),
    days: Yup.number().typeError("Keine gültige Zahl").integer("Bitte geben Sie nur ganze Zahlen ein").min(0, "Bitte geben Sie eine positive Zahl ein").required("Bitte geben Sie die Anzahl der Tage ein"),
    visits: Yup.number().typeError("Keine gültige Zahl").integer("Bitte geben Sie nur ganze Zahlen ein").min(0, "Bitte geben Sie eine positive Zahl ein")
  });

  function onSubmit(values) {
    props.storeValues(values);
    nextHandler();
  }

  function nextHandler() {
    props.nextStepHandler();
  }

  function prevHandler(e) {
    e.preventDefault();
    props.prevStepHandler();
  }

  return (
    <div>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Unterbringung und Gastgewerbebesuche</h3>
            <p className="mt-4 text-xs text-gray-600">* Pflichtfelder</p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <Formik initialValues={props.formData || initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount enableReinitialize>
            {formik => (
              <Form>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-4 gap-6">
                      <div className="col-span-4">
                        <FormControl control="select" label="Kategorie der ausgewählten Unterkunft *" name="stars" options={gastro} />
                      </div>
                      <div className="col-span-4">
                        <FormControl control="number" label="Anzahl der Übernachtungen während des Aufenthalts: *" name="days" />
                      </div>
                      <div className="col-span-4">
                        <FormControl control="number" label="Anzahl der Besuche verschiedener Gastgewerbearten (z. B. Restaurant, Café oder Pub)" name="visits" />
                      </div>
                      <div className="col-span-4 -mt-2 ">
                        <LightInfoBox info='Bitte bilden sie hier das Summenprodukt aus Besuchen multipliziert mit den jeweils beteiligten Personen. Bsp.: Zwei Restaurantbesuche mit jeweils vier Personen ergibt die Anzahl "8".' />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex space-x-2 justify-between md:justify-end">
                    {props.stepNumber > 0 && (
                      <button onClick={prevHandler} type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Zurück
                      </button>
                    )}

                    <button disabled={!formik.isValid} type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-20">
                      Weiter
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Step_1;
