export const SET_CONFIG = "SET_CONFIG";

export function setConfig(type, impressum, datenschutz, abschlusslink, partnerlogo) {
  return {
    type: SET_CONFIG,
    config: {
      type,
      impressum,
      datenschutz,
      abschlusslink,
      partnerlogo
    }
  };
}
