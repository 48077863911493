import React from "react";
import { Field, ErrorMessage } from "formik";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

function Input(props) {
  const { label, name, after, ...rest } = props;

  const styleValid = "flex-1 rounded-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-l-md z-10";

  const styleInValid = "flex-1 rounded-none block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-l-md z-10";

  return (
    <div className="flex flex-col justify-between h-full">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700" dangerouslySetInnerHTML={{ __html: label }}></label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <Field id={name} name={name} {...rest}>
          {({ field, form, meta }) => {
            const isValid = !(meta.touched && meta.error);
            return (
              <div className="flex">
                <input type="number" {...field} {...rest} className={isValid ? styleValid : styleInValid} />
                <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm" dangerouslySetInnerHTML={{ __html: after }}></span>
              </div>
            );
          }}
        </Field>
      </div>
      <ErrorMessage name={name}>
        {error => (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {error}
          </p>
        )}
      </ErrorMessage>
    </div>
  );
}

export default Input;
