import React from "react";
import { Field, ErrorMessage } from "formik";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

function Input(props) {
  const { label, name, before, ...rest } = props;

  const styleValidRight = "flex-1 rounded-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-r-md text-center z-10";
  const styleInValidRight = "flex-1 rounded-none block w-full border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-r-md text-center z-10";

  const styleValidLeft = "flex-1 rounded-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-l-md text-center z-10";
  const styleInValidLeft = "flex-1 rounded-none block w-full border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-l-md text-center z-10";

  return (
    <div>
      <p className="block text-sm font-medium text-gray-700 text-center">{label}</p>
      <div className="mt-1 relative rounded-md shadow-sm flex">
        <Field id={name + "_h"} name={name + "_h"} {...rest}>
          {({ field, form, meta }) => {
            const isValid = !(meta.touched && meta.error);
            return (
              <>
                <label htmlFor={name + "_h"} className="sr-only">
                  {label}
                </label>
                <input type="number" {...field} {...rest} className={isValid ? styleValidLeft : styleInValidLeft} placeholder="HH" />
              </>
            );
          }}
        </Field>
        <span className="inline-flex items-center px-3 border border-x-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm z-0">:</span>
        <Field id={name + "_m"} name={name + "_m"} {...rest}>
          {({ field, form, meta }) => {
            const isValid = !(meta.touched && meta.error);
            return (
              <>
                <label htmlFor={name + "_m"} className="sr-only">
                  {label}
                </label>
                <input type="number" {...field} {...rest} className={isValid ? styleValidRight : styleInValidRight} placeholder="MM" />
              </>
            );
          }}
        </Field>
      </div>
    </div>
  );
}

export default Input;
