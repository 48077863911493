import { SET_RESULTS, START_APP } from "../actions/app";

const initialState = {
  results: null,
  appStarted: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RESULTS:
      return {
        ...state,
        results: action.results
      };
    case START_APP:
      return {
        ...state,
        appStarted: true
      };
  }

  return state;
};
