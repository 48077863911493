import React from "react";
import { Field, ErrorMessage } from "formik";

function SimpleCheckbox(props) {
  const { label, name, options, fullLine, ...rest } = props;
  return (
    <div className="relative flex items-start">
      <div className="mr-3 text-sm">
        <label htmlFor={name} className="font-medium text-gray-700" dangerouslySetInnerHTML={{ __html: label }} />
      </div>
      <div className="flex items-center h-5">
        <Field type="checkbox" name={name} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer" />
      </div>
    </div>
  );
}

export default SimpleCheckbox;
