import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import Page from "../components/Page";
import { Leaf } from "../components/Icons/CustomIcons";

const greeting = '<p class="pb-2">Der ZENAPA CO<sub>2</sub>e-Kalkulator dient der schnellen Berechnung von Treibhausgas-Emissionen Ihrer Reise.</p><p>Der Rechner ermöglicht dem/der Anwender/in durch Eingaben in die entsprechenden Eingabefelder ein Ergebnis für die durch Unterbringung, Gastgewerbebesuche und Anreise verursachten Treibhausgas-Emissionen zu ermitteln. Die Anzeige des Ergebnisses erfolgt in kg CO<sub>2</sub>-Äquivalenten (kg CO<sub>2</sub>e).</p>';

const infoText = "Bitte betätigen Sie während der Verwendung des Rechners nicht die Vor- oder Zurücktaste Ihres Browsers. Dies würde zum Verlust Ihrer Eingaben führen.";

function Result() {
  const results = useSelector(state => state.app.results);
  const abschlusslink = useSelector(state => state.config.abschlusslink);
  const { co2_index } = results;

  const link = {
    to: `${abschlusslink.link}/?kg=${parseFloat(results.co2_2.toFixed(2)).toLocaleString("de-DE")}`,
    text: abschlusslink.linktext
  };

  let level = "";
  let leafs = [];
  let color = "";

  if (co2_index < 10) {
    level = "in hohem Maße klimaschonend";
    leafs = [1, 1, 1, 1, 1, 1];
    color = "text-green-500";
  } else if (co2_index < 20) {
    level = "klimaschonend";
    leafs = [1, 1, 1, 1, 1, 0];
    color = "text-lime-500";
  } else if (co2_index < 40) {
    level = "prinzipiell klimaschonend";
    leafs = [1, 1, 1, 1, 0, 0];
    color = "text-yellow-300";
  } else if (co2_index < 80) {
    level = "im Großen und Ganzen klimaschonend";
    leafs = [1, 1, 1, 0, 0, 0];
    color = "text-amber-400";
  } else if (co2_index < 150) {
    level = "klimarelevant";
    leafs = [1, 1, 0, 0, 0, 0];
    color = "text-amber-500";
  } else if (co2_index < 250) {
    level = "klimabedrohlich";
    leafs = [1, 0, 0, 0, 0, 0];
    color = "text-red-500";
  } else if (co2_index > 250) {
    level = "klimaschädlich";
    leafs = [0, 0, 0, 0, 0, 0];
    color = "text-gray-400";
  }

  return (
    <Page dashboard>
      <div className="max-w-2xl mx-auto">
        <div className="bg-white p-8 shadow rounded-md text-gray-500 w-full">
          <div className="text-center py-6 pt-0 border-b-4 border-gray-100 text-gray-400">
            <h1 className="font-normal text-xl tracking-widest">ERGEBNIS</h1>
          </div>
          <div className="mt-8 space-y-4">
            <div className="flex flex-col text-center sm:justify-between sm:flex-row sm:text-left">
              <div>Gastronomie</div>
              <div className="font-bold">{parseFloat(results.co2_1.toFixed(2)).toLocaleString("de-DE")} kg</div>
            </div>
            <div className="flex flex-col text-center sm:justify-between sm:flex-row sm:text-left">
              <div>Gastronomie sowie An- und Abreise</div>
              <div className="font-bold">{parseFloat(results.co2_2.toFixed(2)).toLocaleString("de-DE")} kg</div>
            </div>
          </div>
        </div>
        <div className="bg-white p-8 shadow rounded-md text-gray-500 w-full mt-6">
          <div className={color + " font-medium text-center"}>{level}</div>
          <div className="flex mt-4 justify-center">{leafs.map((e, i) => (e === 0 ? <Leaf key={i} className="text-gray-200 h-8 w-auto" /> : <Leaf key={i} className={color + " h-8 w-auto"} />))}</div>
          {/* <div className="flex mt-4 justify-center">
            <Leaf className="text-red-500 h-8 w-auto" />
            <Leaf className="text-amber-500 h-8 w-auto" />
            <Leaf className="text-amber-400 h-8 w-auto" />
            <Leaf className="text-yellow-300 h-8 w-auto" />
            <Leaf className="text-lime-500 h-8 w-auto" />
            <Leaf className="text-green-500 h-8 w-auto" />
          </div> */}
        </div>
        {link && (
          <div className="flex justify-center items-center pt-10">
            <a target="_blank" href={link.to} className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-4 text-md lg:px-4 lg:py-2 lg:text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto disabled:opacity-50 disabled:cursor-not-allowed">
              {link.text}
            </a>
          </div>
        )}
      </div>
    </Page>
  );
}

export default Result;
