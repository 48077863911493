import { FLASHMESSAGE__SHOP, FLASHMESSAGE__DASHBOARD, RESET__FLASHMESSAGE, SET_CART_ITEMS } from "../actions/ui";

const initialState = {
  ShopFlashMessages: [],
  DashboardFlashMessages: [],
  cartItems: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FLASHMESSAGE__SHOP:
      return {
        ...state,
        ShopFlashMessages: [action.msg]
      };
    case FLASHMESSAGE__DASHBOARD:
      return {
        ...state,
        DashboardFlashMessages: [action.msg]
      };
    case RESET__FLASHMESSAGE:
      return {
        ...state,
        DashboardFlashMessages: [],
        ShopFlashMessages: []
      };
    case SET_CART_ITEMS:
      return {
        ...state,
        cartItems: action.count < 0 ? 0 : action.count
      };
  }

  return state;
};
