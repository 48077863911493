import React, { useRef, useState, useEffect } from "react";
import { PaperClipIcon } from "@heroicons/react/solid";
import { Field, ErrorMessage, Formik } from "formik";

function SingleFilePicker(props) {
  const { name, formik, label, fileTypes } = props;

  const [file, setFile] = useState(null);
  // const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef();

  useEffect(() => {
    if (file) {
      formik.setFieldValue(name, file);
    }
  }, [file]);

  function handleClick(e) {
    e.preventDefault();

    fileInputRef.current.click();
  }

  function handleFileChange(e) {
    const file = e.target.files[0];

    if (file) {
      setFile(file);
    } else {
      setFile(null);
    }
  }

  return (
    <div>
      <label htmlFor="coverImg" className="block text-sm font-medium text-gray-700 mb-4">
        {label}
      </label>
      <div className="grid grid-cols-4 gap-6">
        <div>
          <input type="file" id="coverImg" name="coverImg" className="sr-only" ref={fileInputRef} onChange={handleFileChange} accept={fileTypes} />
          <div className="flex items-center">
            <button onClick={handleClick} type="button" className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Datei auswählen
            </button>
          </div>
        </div>
        {file && (
          <div className="flex-1 flex items-center col-span-3">
            <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            <span className="ml-2 flex-1 w-0 truncate text-sm text-gray-700">{file.name + " " + `[ ${(file.size / 1024 / 1024).toFixed(2).toString().replace(".", ",")} MB ]`}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default SingleFilePicker;
