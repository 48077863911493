export const SET_RESULTS = "SET_RESULTS";
export const START_APP = "START_APP";

export function setResults(results) {
  return {
    type: SET_RESULTS,
    results: results
  };
}

export function startApp() {
  return {
    type: START_APP
  };
}
