import React from "react";
import { Field, ErrorMessage } from "formik";

function Range(props) {
  const { name, ...rest } = props;

  return (
    <Field id={name} name={name} {...rest}>
      {({ field, form, meta }) => {
        return <input type="range" {...field} {...rest} />;
      }}
    </Field>
  );
}

export default Range;
