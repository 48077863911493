import { SET_CONFIG } from "../actions/config";

const initialState = {
  impressum: window.globalTS.impressum,
  datenschutz: window.globalTS.datenschutz,
  abschlusslink: window.globalTS.abschlusslink,
  partnerlogo: window.globalTS.partnerlogo,
  partnerlink: window.globalTS.partnerlink
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        ...action.config
      };
  }

  return state;
};
