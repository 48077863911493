import React from "react";
import { Field, ErrorMessage } from "formik";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

function Select(props) {
  const { label, name, options, before, ...rest } = props;

  const styleValid = "flex-1 rounded-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-r-md";

  const styleInValid = "flex-1 rounded-none block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-r-md";

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <Field id={name} name={name} {...rest}>
        {({ field, form, meta }) => {
          const isValid = !(meta.touched && meta.error);
          return (
            <div className="flex">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">{before}</span>
              <select {...field} className={isValid ? styleValid : styleInValid}>
                {options.map(option => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.key}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        }}
      </Field>
      <ErrorMessage name={name}>
        {error => (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {error}
          </p>
        )}
      </ErrorMessage>
    </div>
  );
}

export default Select;
