import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";

// Store
import * as uiActions from "../../store/actions/ui";
import * as appActions from "../../store/actions/app";

// Components
import { DottedSpinner } from "../../components/Icons/CustomIcons";
import Page from "../../components/Page";
import Steps from "../../components/Steps";
import StepSelector from "../../pages/Shop/CalculatorSteps/StepSelector";

function Calculator(props) {
  const [steps, setSteps] = useState([
    { name: "Allgemeine Angabe", status: "current" },
    { name: "Unterbringung und Gastgewerbebesuche", status: "upcoming" },
    { name: "Anreise - Verkehrsmittel", status: "upcoming" },
    { name: "Mobilität vor Ort", status: "upcoming" }
  ]);

  const [formData, setFormData] = useState([]);
  const [curStep, setCurStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownData, setDropdownData] = useState({});

  const user = useSelector(state => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams("id");

  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const response = await axios.get(`/calculator/dropdowns`, { cancelToken: fetchRequest.token });
        setDropdownData(response.data);
        setIsLoading(false);
      } catch (e) {
        dispatch(uiActions.flashMessageShop("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
        if (!requestAborted) {
          console.log("There was an error");
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  function nextStepHandler() {
    setSteps(s => {
      const newStep = s;
      newStep[curStep].status = "complete";
      newStep[curStep + 1].status = "current";
      return newStep;
    });
    setCurStep(s => s + 1);
  }

  function prevStepHandler() {
    setSteps(s => {
      const newStep = s;
      newStep[curStep].status = "upcoming";
      newStep[curStep - 1].status = "current";
      return newStep;
    });
    setCurStep(s => s - 1);
  }

  function storeValues(values) {
    setFormData(s => {
      const newData = s;
      newData[curStep] = values;
      return newData;
    });
  }

  async function calculate(values) {
    const resultData = formData;
    resultData[curStep] = values;

    const refactoredData = dataRefactor({ ...resultData[0], ...resultData[1], ...resultData[2], ...resultData[3] });

    await sendData(refactoredData);
  }

  async function sendData(data) {
    try {
      const response = await axios.post("calculator/calculate", data);
      dispatch(
        appActions.setResults({
          co2_1: response.data.co2_1,
          co2_2: response.data.co2_2,
          co2_index: response.data.co2_2 / data.person_count / data.days
        })
      );

      navigate("/result", { replace: true });
    } catch (e) {
      dispatch(uiActions.flashMessageShop("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
      console.log("There was a problem.", e);
    }
  }

  function dataRefactor(data) {
    // refactor data
    const newData = { ...data };

    newData.ice_time_h = newData.ice_time_h === "" ? 0 : newData.ice_time_h;
    newData.ice_time_m = newData.ice_time_m === "" ? 0 : newData.ice_time_m;
    newData.ic_ec_time_h = newData.ic_ec_time_h === "" ? 0 : newData.ic_ec_time_h;
    newData.ic_ec_time_m = newData.ic_ec_time_m === "" ? 0 : newData.ic_ec_time_m;
    newData.re_rb_time_h = newData.re_rb_time_h === "" ? 0 : newData.re_rb_time_h;
    newData.re_rb_time_m = newData.re_rb_time_m === "" ? 0 : newData.re_rb_time_m;
    newData.plane_time_h = newData.plane_time_h === "" ? 0 : newData.plane_time_h;
    newData.plane_time_m = newData.plane_time_m === "" ? 0 : newData.plane_time_m;

    newData.mo_ice_time_h = newData.mo_ice_time_h === "" ? 0 : newData.mo_ice_time_h;
    newData.mo_ice_time_m = newData.mo_ice_time_m === "" ? 0 : newData.mo_ice_time_m;
    newData.mo_ic_ec_time_h = newData.mo_ic_ec_time_h === "" ? 0 : newData.mo_ic_ec_time_h;
    newData.mo_ic_ec_time_m = newData.mo_ic_ec_time_m === "" ? 0 : newData.mo_ic_ec_time_m;
    newData.mo_re_rb_time_h = newData.mo_re_rb_time_h === "" ? 0 : newData.mo_re_rb_time_h;
    newData.mo_re_rb_time_m = newData.mo_re_rb_time_m === "" ? 0 : newData.mo_re_rb_time_m;

    // calculate minutes
    newData.ice_time = newData.ice_time_h * 60 + newData.ice_time_m;
    newData.ic_ec_time = newData.ic_ec_time_h * 60 + newData.ic_ec_time_m;
    newData.re_rb_time = newData.re_rb_time_h * 60 + newData.re_rb_time_m;
    newData.plane_time = newData.plane_time_h * 60 + newData.plane_time_m;

    newData.mo_ice_time = newData.mo_ice_time_h * 60 + newData.mo_ice_time_m;
    newData.mo_ic_ec_time = newData.mo_ic_ec_time_h * 60 + newData.mo_ic_ec_time_m;
    newData.mo_re_rb_time = newData.mo_re_rb_time_h * 60 + newData.mo_re_rb_time_m;

    // cleanup object
    delete newData.ice_time_h;
    delete newData.ice_time_m;
    delete newData.ic_ec_time_h;
    delete newData.ic_ec_time_m;
    delete newData.re_rb_time_h;
    delete newData.re_rb_time_m;
    delete newData.plane_time_h;
    delete newData.plane_time_m;

    delete newData.mo_ice_time_h;
    delete newData.mo_ice_time_m;
    delete newData.mo_ic_ec_time_h;
    delete newData.mo_ic_ec_time_m;
    delete newData.mo_re_rb_time_h;
    delete newData.mo_re_rb_time_m;

    // set default values
    if (!newData.kfz_km) {
      newData.kfz_km = 0;
    }
    if (!newData.bus_near_km) {
      newData.bus_near_km = 0;
    }
    if (!newData.bus_far_km) {
      newData.bus_far_km = 0;
    }

    if (!newData.mo_kfz_km) {
      newData.mo_kfz_km = 0;
    }
    if (!newData.mo_bus_near_km) {
      newData.mo_bus_near_km = 0;
    }
    if (!newData.mo_bus_far_km) {
      newData.mo_bus_far_km = 0;
    }

    if (!newData.visits) {
      newData.visits = 0;
    }

    return newData;
  }

  return (
    <Page dashboard title="Neues Angebot">
      {isLoading ? (
        <div className="flex justify-center h-screen mt-16">
          <DottedSpinner className="h-10 w-10 animate-spin text-gray-500" />
        </div>
      ) : (
        <>
          <Steps stepData={steps} />
          <StepSelector step={curStep} formData={formData} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} dropdownData={dropdownData} calculate={calculate} />
        </>
      )}
    </Page>
  );
}

export default Calculator;
