import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Components
import Page from "../components/Page";
import LightInfoBox from "../components/LightInfoBox";

// Store
import * as appActions from "../store/actions/app";

const greeting = '<p class="pb-2">Der ZENAPA CO<sub>2</sub>-Rechner dient der schnellen Berechnung von Treibhausgasemissionen, die in Zusammenhang mit Ihrer Reise entstehen.</p><p>Durch den Rechner ist es möglich anhand von Angaben die durch Unterbringung, Gastgewerbebesuche und Anreise verursachten Treibhausgas-Emissionen zu ermitteln. Die Anzeige des Ergebnisses erfolgt in kg CO<sub>2</sub>-Äquivalenten (kg CO<sub>2</sub>e).</p>';

const infoText = "Bitte betätigen Sie während der Verwendung des Rechners nicht die Vor- oder Zurücktaste Ihres Browsers. Dies würde zum Verlust Ihrer Eingaben führen.";

const link = {
  to: "calculator",
  text: "Starten"
};

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleClick() {
    dispatch(appActions.startApp());
    navigate(link.to, { replace: true });
  }

  return (
    <Page dashboard>
      <div className="max-w-4xl mx-auto">
        <div className="bg-white p-8 shadow rounded-md text-gray-500 w-full">
          <div className="text-md break-words" dangerouslySetInnerHTML={{ __html: greeting }}></div>
          {/* <div className="mt-8">
            <LightInfoBox info={infoText} />
          </div> */}
        </div>
        {link && (
          <div className="flex justify-center items-center pt-10">
            <button onClick={handleClick} type="button" className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-4 text-md lg:px-4 lg:py-2 lg:text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto disabled:opacity-50 disabled:cursor-not-allowed">
              {link.text}
            </button>
          </div>
        )}
      </div>
    </Page>
  );
}

export default Home;
