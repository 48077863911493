import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Components
import FormControl from "../../../components/FormUI/FormControl";
import { DottedSpinner } from "../../../components/Icons/CustomIcons";

// Default Values
const fuelDefault = "Benzin";
const fuelWasteDefault = "8";

function Step_2(props) {
  const [defaults, setDefaults] = useState({
    fuel: "",
    fuelWaste: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filteredFuel, setFilteredFuel] = useState([]);

  useEffect(() => {
    const fuelId = props.dropdownData.fuel_dropdown.filter(f => f.title === fuelDefault);

    setDefaults({
      fuel: props.prevFormData.fuel,
      fuelWaste: props.prevFormData.fuel_waste
    });
  }, []);

  useEffect(() => {
    const tmp = props.dropdownData.fuel_dropdown.filter(f => f.title !== "Kerosin");

    setFilteredFuel(tmp);
  }, []);

  const initialValues = {
    mo_kfz_km: "",
    mo_bus_near_km: "",
    mo_bus_far_km: "",
    mo_ice_time_h: "",
    mo_ice_time_m: "",
    mo_ic_ec_time_h: "",
    mo_ic_ec_time_m: "",
    mo_re_rb_time_h: "",
    mo_re_rb_time_m: "",
    mo_fuel: defaults.fuel,
    mo_fuel_waste: defaults.fuelWaste
  };

  const validationSchema = Yup.object({
    mo_kfz_km: Yup.number().typeError("Keine gültige Zahl").integer("Bitte geben Sie nur ganze Zahlen ein").min(0, "Bitte geben Sie eine positive Zahl ein").max(25000, "Es dürfen maximal 25.000 km eingegeben werden"),
    mo_bus_near_km: Yup.number().typeError("Keine gültige Zahl").integer("Bitte geben Sie nur ganze Zahlen ein").min(0, "Bitte geben Sie eine positive Zahl ein").max(25000, "Es dürfen maximal 25.000 km eingegeben werden"),
    mo_bus_far_km: Yup.number().typeError("Keine gültige Zahl").integer("Bitte geben Sie nur ganze Zahlen ein").min(0, "Bitte geben Sie eine positive Zahl ein").max(25000, "Es dürfen maximal 25.000 km eingegeben werden"),
    mo_ice_time_h: Yup.number().typeError("Keine gültige Zahl").integer().min(0).max(1000),
    mo_ice_time_m: Yup.number().typeError("Keine gültige Zahl").integer().min(0).max(59),
    mo_ic_ec_time_h: Yup.number().typeError("Keine gültige Zahl").integer().min(0).max(1000),
    mo_ic_ec_time_m: Yup.number().typeError("Keine gültige Zahl").integer().min(0).max(59),
    mo_re_rb_time_h: Yup.number().typeError("Keine gültige Zahl").integer().min(0).max(1000),
    mo_re_rb_time_m: Yup.number().typeError("Keine gültige Zahl").integer().min(0).max(59),
    mo_fuel: Yup.string().required("Bitte wählen Sie die zutreffende Option aus"),
    mo_fuel_waste: Yup.number().typeError("Keine gültige Zahl").positive("Bitte geben Sie eine positive Zahl ein").max(50, "Der Kraftstoffverbrauch darf höchstens 50 Liter betragen").required("Bitte geben Sie Ihren durchschnittlichen Kraftstoffverbrach ein")
  });

  function onSubmit(values) {
    setIsSubmitting(true);
    props.calculate(values);
  }

  function nextHandler() {
    props.nextStepHandler();
  }

  function prevHandler(e) {
    e.preventDefault();
    props.prevStepHandler();
  }

  return (
    <div>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Mobilität vor Ort</h3>
            <p className="mt-4 text-xs text-gray-600">Bitte zurückgelegte (einfache) Fahrtstrecke(n) angeben</p>
            <p className="mt-4 text-xs text-gray-600">* Pflichtfelder</p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <Formik initialValues={props.formData || initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount enableReinitialize>
            {formik => (
              <Form>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="grid grid-cols-3 gap-6 col-span-6">
                        <div className="col-span-3 sm:col-span-1">
                          <FormControl control="number" label="PKW" name="mo_kfz_km" after="km" autoFocus />
                        </div>
                        <div className="col-span-3 sm:col-span-1">
                          <FormControl control="number" label="Linienbus - Nahverkehr" name="mo_bus_near_km" after="km" />
                        </div>
                        <div className="col-span-3 sm:col-span-1">
                          <FormControl control="number" label="Linienbus - Fernverkehr" name="mo_bus_far_km" after="km" />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <FormControl control="traveltime" label="Bahn (ICE)" name="mo_ice_time" />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <FormControl control="traveltime" label="Bahn (IC/EC)" name="mo_ic_ec_time" />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <FormControl control="traveltime" label="Bahn (RE/RB)" name="mo_re_rb_time" />
                      </div>
                      {/* Only if a car was used */}
                      {formik.values.mo_kfz_km !== "" && (
                        <>
                          <div className="col-span-6 sm:col-span-3">
                            <FormControl control="select" label="Welchen Kraftstoff benötigt ihr Fahrzeug? *" name="mo_fuel" options={filteredFuel} />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <FormControl control="number" label="Wie hoch schätzen Sie den Ø-Verbrauch pro 100 km des KFZ? *" name="mo_fuel_waste" after={`${formik.values.mo_fuel == 3847 || formik.values.mo_fuel == 3848 ? "kWh" : "l"}/100 km`} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex space-x-2 justify-between md:justify-end">
                    {props.stepNumber > 0 && (
                      <button onClick={prevHandler} type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Zurück
                      </button>
                    )}

                    <button disabled={!formik.isValid | isSubmitting} type="submit" className="relative inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-20">
                      {isSubmitting && <DottedSpinner className="h-5 w-5 animate-spin absolute" />}
                      <span className={isSubmitting ? "invisible" : ""}>Berechnen</span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Step_2;
