import React from "react";
import { Field, ErrorMessage } from "formik";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

function Select(props) {
  const { label, name, options, ...rest } = props;

  const styleValid = "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md";

  const styleInValid = "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md";

  return (
    <div className="flex flex-col justify-between h-full">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <Field id={name} name={name} {...rest}>
        {({ field, form, meta }) => {
          const isValid = !(meta.touched && meta.error);
          return (
            <div className="mt-1">
              <select {...field} className={isValid ? styleValid : styleInValid}>
                {options.map(option => {
                  return (
                    <option key={option.id} value={option.id}>
                      {option.title}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        }}
      </Field>
      <ErrorMessage name={name}>
        {error => (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {error}
          </p>
        )}
      </ErrorMessage>
    </div>
  );
}

export default Select;
