export function DottedSpinner(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 64 64" {...props}>
      <g>
        <path d="M32,64A32,32,0,1,1,64,32,32.036,32.036,0,0,1,32,64ZM32,6A26,26,0,1,0,58,32,26.03,26.03,0,0,0,32,6Z" opacity="0.4"></path> <path d="M64,32H58A26.03,26.03,0,0,0,32,6V0A32.036,32.036,0,0,1,64,32Z"></path>
      </g>
    </svg>
  );
}

export function Leaf(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 48 48" {...props}>
      <g>
        <path d="M44.7,3.3C44.5,3.1,44.2,3,43.8,3c-0.9,0.1-21.9,3.7-30.5,12.3c-6.6,6.6-6.2,14-5.1,18.4l10.3-7 c0.5-0.3,1.1,0.3,0.6,0.8L3.3,43.3c-0.4,0.4-0.4,1,0,1.4C3.5,44.9,3.7,45,4,45s0.5-0.1,0.7-0.3l6.2-6.2c1.3,0.6,4.5,1.9,8.6,1.9 c5,0,9.5-1.9,13.3-5.7C42,25.5,44.9,5,45,4.1C45,3.8,44.9,3.5,44.7,3.3z" />
      </g>
    </svg>
  );
}
