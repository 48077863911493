import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

// Pages
import Home from "./pages/Home";
import Calculator from "./pages/Shop/Calculator";
import Result from "./pages/Result";

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";

// Setup API Base URL
axios.defaults.baseURL = "https://zenapa.de/index.php/wp-json";
// axios.defaults.baseURL = "http://zenapa.local/index.php/wp-json";

function App(props) {
  const appStarted = useSelector(state => state.app.appStarted);
  const results = useSelector(state => state.app.results);

  return (
    <BrowserRouter>
      <div className="overflow-hidden">
        <Header />
        {/* Main Router */}
        <Suspense fallback="">
          <Routes>
            <Route path="/" element={<Home />} />

            {appStarted && (
              <>
                <Route path="/calculator" element={<Calculator />} />
                {results && <Route path="/result" element={<Result />} />}
              </>
            )}

            {/* 404 Route */}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Suspense>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
