import React from "react";

// Steps
import Step_0 from "./Step_0";
import Step_1 from "./Step_1";
import Step_2 from "./Step_2";
import Step_3 from "./Step_3";

function StepSelector({ step, storeValues, nextStepHandler, prevStepHandler, formData, dropdownData, calculate }) {
  switch (step) {
    case 0:
      return <Step_0 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} />;
    case 1:
      return <Step_1 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} dropdownData={dropdownData} />;
    case 2:
      return <Step_2 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} dropdownData={dropdownData} />;
    case 3:
      return <Step_3 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} prevFormData={formData[step - 1]} dropdownData={dropdownData} calculate={calculate} />;

    default:
      return <h1>Step not found</h1>;
  }
}

export default StepSelector;
